export enum BedType {
  KING = 'KING',
  QUEEN = 'QUEEN',
  DOUBLE = 'DOUBLE',
  PERSONAL = 'PERSONAL',
  BIG_PERSONAL = 'BIG PERSONAL',
  BUNK_BED = 'BUNK BED',
  SOFA_BED = 'SOFA BED',
  CHILD_BED = 'CHILD BED',
  FLOOR_MATTRESS = 'FLOOR MATTRESS',
}

export enum BedIcon {
  KING = 'bed-king',
  QUEEN = 'bed-queen',
  DOUBLE = 'bed-double',
  PERSONAL = 'bed-personal',
  BIG_PERSONAL = 'bed-big-personal',
  BUNK_BED = 'bed-bunk',
  SOFA_BED = 'bed-sofa-1',
  CHILD_BED = 'bed-child',
  FLOOR_MATTRESS = 'mattress'
}

export enum FavIcon {
  FAV = 'fav',
}
