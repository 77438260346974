import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconListItemComponent } from './icon-list-of-items/icon-list-item.component';
import { IconListOfItemsComponent } from './icon-list-of-items/icon-list-of-items.component';
import { IconListItemNoNameComponent } from './icon-list-of-items-no-name/icon-list-item-no-name.component';
import { IconListOfItemsNoNameComponent } from './icon-list-of-items-no-name/icon-list-of-items-no-name.component';

const COMPONENTS = [
  IconListItemComponent,
  IconListOfItemsComponent,
  IconListItemNoNameComponent,
  IconListOfItemsNoNameComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class IconListOfItemsModule { }
