import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CurrencyKeyType, User, LANGUAGES } from '@rhbnb-nx-ws/domain';

import * as fromCore from './reducer';
import * as actions from './actions';
import * as selectors from './selectors';
import { Observable } from 'rxjs';
import { StateCurrency } from './reducer';

@Injectable({
  providedIn: 'root'
})
export class CoreStoreService {

  constructor(
    private store: Store<fromCore.State>,
  ) {}

  setUser(user: User): void {
    this.store.dispatch(actions.setCurrentUser({user}));
  }

  getUser(): Observable<User> {
    return this.store.select(selectors.selectUser);
  }

  setCountry(country: string) {
    this.store.dispatch(actions.setCountry({ country }));
  }

  getCountry(): Observable<string> {
    return this.store.select(selectors.selectCountry);
  }

  updateUserTCC(tcc: number): void {
    this.store.dispatch(actions.updateUserTCC({tcc}));
  }

  getUserTCC(): Observable<number> {
    return this.store.select(selectors.selectUserTCC);
  }

  loadCurrencyTable(): void {
    this.store.dispatch(actions.loadCurrencyTable());
  }

  loadUserChatToken(): void {
    this.store.dispatch(actions.loginChatUser());
  }

  loadChatUnreadCount(): void {
    this.store.dispatch(actions.loadChatUnreadCount());
  }

  setChatAuthToken(token: string): void {
    this.store.dispatch(actions.setChatAuthToken({token}));
  }

  getChatAuthToken(): Observable<string> {
    return this.store.select(selectors.selectChatAuthToken);
  }

  incChatUnreadCount(count: number): void {
    this.store.dispatch(actions.incChatUnreadCount({count}));
  }

  decChatUnreadCount(count: number): void {
    this.store.dispatch(actions.decChatUnreadCount({count}));
  }

  setChatUnreadCount(count: number): void {
    this.store.dispatch(actions.setChatUnreadCount({count}));
  }

  getChatUnreadCount(): Observable<number> {
    return this.store.select(selectors.selectChatUnreadCount);
  }

  setChatUnreadIndicatorSpace(space: string): void {
    this.store.dispatch(actions.setChatUnreadIndicatorSpace({space}));
  }

  getChatUnreadIndicatorSpace(): Observable<string> {
    return this.store.select(selectors.selectChatUnreadIndicatorSpace);
  }

  setChatFail(fail: boolean): void {
    this.store.dispatch(actions.setChatFail({fail}));
  }

  getChatFail(): Observable<boolean> {
    return this.store.select(selectors.selectChatFail);
  }

  setChatWindowOpen(open: boolean, entity: string, id: string): void {
    this.store.dispatch(actions.setChatWindowOpen({open, entity, id}));
  }

  getChatWindowOpen(): Observable<boolean> {
    return this.store.select(selectors.selectChatWindowOpen);
  }

  setChatWaitingRoom(waiting: boolean): void {
    this.store.dispatch(actions.setChatWaitingRoom({waiting}));
  }

  getChatWaitingRoom(): Observable<boolean> {
    return this.store.select(selectors.selectChatWaitingRoom);
  }

  getChatSelectedEntity(): Observable<string> {
    return this.store.select(selectors.selectChatSelectedEntity);
  }

  getChatSelectedId(): Observable<string> {
    return this.store.select(selectors.selectChatSelectedId);
  }

  setLoginUserLoading(loading: boolean): void {
    this.store.dispatch(actions.setLoginUserLoading({loading}));
  }

  setMetaTitle(title: string): void {
    this.store.dispatch(actions.setMetaTitle({title}));
  }

  setMetaUrl(url: string): void {
    this.store.dispatch(actions.setMetaUrl({url}));
  }

  setMetaDescription(description: string): void {
    this.store.dispatch(actions.setMetaDescription({description}));
  }

  setMetaImage(image: string): void {
    this.store.dispatch(actions.setMetaImage({image}));
  }

  setJsonLdSchema(schema: Object): void {
    this.store.dispatch(actions.setJsonLdSchema({schema}));
  }

  getLoginUserLoading(): Observable<boolean> {
    return this.store.select(selectors.selectLoginUserLoading);
  }

  setCurrencyTable(value: StateCurrency): void {
    this.store.dispatch(actions.setCurrencyTable({value}));
  }

  getCurrencyTable(): Observable<StateCurrency> {
    return this.store.select(selectors.selectCurrencyTable);
  }

  setCurrentCurrency(value: CurrencyKeyType): void {
    this.store.dispatch(actions.setCurrentCurrency({value}));
  }

  getCurrentCurrency(): Observable<CurrencyKeyType> {
    return this.store.select(selectors.selectCurrentCurrency);
  }

  setAvailCurrencies(value: CurrencyKeyType[]): void {
    this.store.dispatch(actions.setAvailCurrencies({value}));
  }

  getAvailCurrencies(): Observable<CurrencyKeyType[]> {
    return this.store.select(selectors.selectAvailCurrencies);
  }

  setCurrentLang(value: LANGUAGES): void {
    this.store.dispatch(actions.setCurrentLang({value}));
  }

  getCurrentLang(): Observable<LANGUAGES> {
    return this.store.select(selectors.selectCurrentLang);
  }

  getMetaTitle(): Observable<string> {
    return this.store.select(selectors.selectMetaTitle);
  }

  getMetaUrl(): Observable<string> {
    return this.store.select(selectors.selectMetaUrl);
  }

  getMetaDescription(): Observable<string> {
    return this.store.select(selectors.selectMetaDescription);
  }

  getMetaImage(): Observable<string> {
    return this.store.select(selectors.selectMetaImage);
  }

  getJsonLdSchema(): Observable<Object> {
    return this.store.select(selectors.selectJsonLdSchema);
  }

  setUpdateAvailable(update: boolean): void {
    this.store.dispatch(actions.setUpdateAvailable({update}));
  }

  getUpdateAvailable(): Observable<boolean> {
    return this.store.select(selectors.selectUpdateAvailable);
  }
}
