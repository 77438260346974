import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  Outing,
  PageData,
  ROLES,
  User,
} from '@rhbnb-nx-ws/domain';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

import { AbstractDataService } from '../util';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Moment } from 'moment';

const CACHE_TTL = 15 * 60 * 1000; // 15 mins

@Injectable({
  providedIn: 'root',
})
export class OutingApiService extends AbstractDataService<Outing> {
  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string
  ) {
    super(http, 'outing', apiURL);
  }

  add(entity: Outing): Observable<ApiResponse<Outing>> {
    return super.add(entity);
  }

  update(entity: Outing): Observable<ApiResponse<Outing>> {
    return super.update(entity);
  }

  patch(entity: Outing): Observable<ApiResponse<Outing>> {
    return super.patch(entity);
  }

  getMeList(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string
  ): Observable<ApiResponse<PageData<Outing>>> {
    return super.getMeList(q, page, limit, sort, order);
  }

  addExtra(
    extra: { extra: string; price: string; outing: string },
    headers = {}
  ): Observable<ApiResponse<Outing>> {
    return this.http
      .post<ApiResponse<Outing>>(
        `${this.apiURL}/${this.endpointName}/${extra?.outing}/extra`,
        extra,
        {
          headers,
        }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  removeExtra(
    outing: string,
    extra: string,
    headers = {}
  ): Observable<ApiResponse<Outing>> {
    return this.http
      .delete<ApiResponse<Outing>>(
        `${this.apiURL}/${this.endpointName}/${outing}/extra/${extra}`,
        {
          headers,
        }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  paginate(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    extraParams?: HttpParams,
    currentUser?: User
  ): Observable<ApiResponse<PageData<Outing>>> {
    let source$ = super.getMeList(q, page, limit, sort, order);

    if (currentUser?.hasRole(ROLES.ROLE_ADMIN)) {
      source$ = super.paginate(q, page, limit, sort, order);
    }

    return source$;
  }

  search(
    {
      page,
      limit,
      province,
      date,
      guests,
      within
    }: {
      page?: string,
      limit?: string,
      province?: string | number,
      date?: string,
      guests?: number,
      within?: number[][]
    }
  ): Observable<ApiResponse<PageData<Outing>>> {
    let params = new HttpParams();

    if (page) {
      params = params.set('_page', page);
    }

    if (limit) {
      params = params.set('_limit', limit);
    }

    if (province) {
      params = params.set('province', `${province}`);
    }

    if (date) {
      params = params.set('date', date);
    }

    if (guests) {
      params = params.set('guests', guests.toString());
    }

    if (within) {
      params = params.append('within', JSON.stringify(within))
    }

    const source$ = this.http.get<ApiResponse<PageData<Outing>>>(
      `${this.apiURL}/${this.endpointName}/search`,
      { ...{ params }, ...{ observe: 'response' } }
    );

    return source$.pipe(
      map((res: HttpResponse<ApiResponse<PageData<Outing>>>) => {
        return {
          data: {
            data: res?.body?.data,
            total: res?.headers?.get('Page-Total'),
          } as any,
          success: res?.body?.success,
        } as ApiResponse<PageData<Outing>>;
      }),
      catchError((error) => observableThrowError(error))
    );
  }

  getWithEvents(id: string, date?: Moment): Observable<ApiResponse<Outing>> {
    let q = '?';

    if (date && date?.isValid()) {
      q += `date=${date.startOf('day').format('YYYY-MM-DD')}`;
    }

    const endpoint = `${this.apiURL}/${this.endpointName}/${id}/with-events${q}`;
    const source$ = this.http.get<ApiResponse<Outing>>(endpoint);

    return source$.pipe(catchError((error) => observableThrowError(error)));
  }

  rank(id: string, value: number): Observable<ApiResponse<Outing>> {
    return this.http
      .put<ApiResponse<Outing>>(
        `${this.apiURL}/${this.endpointName}/${id}/rank`,
        { ranking: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  commit(id: string, value: number): Observable<ApiResponse<Outing>> {
    return this.http
      .put<ApiResponse<Outing>>(
        `${this.apiURL}/${this.endpointName}/${id}/commission`,
        { commission: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  extraCommit(id: string, value: number): Observable<ApiResponse<Outing>> {
    return this.http
      .put<ApiResponse<Outing>>(
        `${this.apiURL}/${this.endpointName}/${id}/extra-commission`,
        { commission: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  toggleActive(id: string, value: boolean): Observable<ApiResponse<Outing>> {
    return this.http
      .put<ApiResponse<Outing>>(
        `${this.apiURL}/${this.endpointName}/${id}/toggle-active`,
        { active: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getWithoutHostManagers(): Observable<ApiResponse<Outing[]>> {
    return this.http
      .get<ApiResponse<Outing[]>>(
        `${this.apiURL}/${this.endpointName}/without-hostmanager`
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  suggested(id: string): Observable<ApiResponse<Outing[]>> {
    const source$ = this.http.get<ApiResponse<Outing[]>>(
      `${this?.apiURL}/${this.endpointName}/suggested/${id}`
    );

    return source$.pipe(catchError((error) => observableThrowError(error)));
  }

  telegramPost(id: string): Observable<ApiResponse<void>> {
    return this.http
      .post<ApiResponse<void>>(
        `${this?.apiURL}/${this.endpointName}/${id}/telegram-post`,
        {}
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }
}
