import { Inject, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import {
  ApiResponse, UserFavorite
} from '@rhbnb-nx-ws/domain';
import { AbstractDataService } from '../util';

import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';

@Injectable({
  providedIn: 'root',
})
export class UserFavoriteApiService extends AbstractDataService<UserFavorite> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
  ) {
    super(http, 'user-favorites', apiURL);
  }

  toggle(user: string, house?: string, outing?: string): Observable<ApiResponse<UserFavorite>> {
    return this.http
      .post<ApiResponse<UserFavorite>>(`${this.apiURL}/${this.endpointName}/toggle`, {
        user,
        house,
        outing
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }
}
