import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { BASE_ICONS_PATH_TOKEN } from '@rhbnb-nx-ws/global-tokens';
import { ItemWithIcon } from '../icon-list-of-items/icon-list-item.component';

@Component({
  selector: 'rhbnb-icon-list-item-no-name',
  template: `
    <div
      class="service-item d-flex flex-wrap align-items-center"
    >
      <div class="me-sm-2 text-center text-sm-start">
        <img [src]="getIconPath(item.iconClass)" [matTooltip]="item.name"/>
      </div>
    </div>
  `,
  styles: [
    `
      .center {
        text-align: center;
      }

      img {
        height: 25px;
        width: auto;
        opacity: 0.5;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconListItemNoNameComponent implements OnInit {
  @Input() item: ItemWithIcon;

  constructor(@Inject(BASE_ICONS_PATH_TOKEN) public iconPath: string) {}

  ngOnInit(): void {}

  getIconPath(icon: string) {
    return `${this.iconPath}/${icon}.svg`;
  }
}
