import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, Integration, IntegrationRooms } from '@rhbnb-nx-ws/domain';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';
import { throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AbstractDataService } from '../util/abstract-data-service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService extends AbstractDataService<Integration> {
  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
  ) {
    super(http, 'integration', apiURL);
  }

  createRelation(integrationId: number, roomIds: number[], active: boolean, ref: string, headers = {}) {
    return this.http
      .post<ApiResponse<void>>(`${this.apiURL}/${this.endpointName}/create-relation/${integrationId}`, { rooms: roomIds, active, ref }, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  runRelation(integrationId: number, headers = {}) {
    return this.http
      .post<ApiResponse<void>>(`${this.apiURL}/${this.endpointName}/run-relation/${integrationId}`, {}, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  updateRelation(id: number, integrationId: number, roomIds: number[], active: boolean, ref: string, headers = {}) {
    return this.http
      .post<ApiResponse<void>>(`${this.apiURL}/${this.endpointName}/update-relation/${id}`, { integration: integrationId, rooms: roomIds, active, ref }, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getRelation(id: number, headers = {}) {
    return this.http
      .get<ApiResponse<IntegrationRooms>>(`${this.apiURL}/${this.endpointName}/get-relation/${id}`, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getRelationsByHouse(houseId: string, headers = {}) {
    return this.http
      .get<ApiResponse<IntegrationRooms[]>>(`${this.apiURL}/${this.endpointName}/by-house/${houseId}`, {
        headers,
      })
      .pipe(catchError((error) => observableThrowError(error)));
  }
}
