import { Currency, CurrencyKeyType, User, LANGUAGES, ROUTE_COUNTRIES } from '@rhbnb-nx-ws/domain';
import { Action, createReducer, on } from '@ngrx/store';
import * as Actions from './actions';

export const coreModuleKey = 'core';

export interface State {
  user: User;
  country: string,
  chatAuthToken: string;
  metaTitle: string;
  metaUrl: string;
  metaDescription: string;
  metaImage: string;
  jsonLdSchema: Object;
  chatFail: boolean;
  chatOpenWindow: boolean;
  chatWaitingRoom: boolean;
  chatSelectedEntity: string;
  chatSelectedId: string;
  chatUnreadCount: number;
  chatUnreadIndicatorSpace: string;
  loginUserLoading: boolean;
  currencyTable: StateCurrency;
  currentCurrency: CurrencyKeyType;
  availCurrencies: CurrencyKeyType[];
  currentLang: LANGUAGES;
  updateAvailable: boolean;
}

const initialState: State = {
  user: undefined,
  country: ROUTE_COUNTRIES.cuba,
  chatAuthToken: undefined,
  metaTitle: undefined,
  metaUrl: undefined,
  metaDescription: undefined,
  metaImage: undefined,
  jsonLdSchema: {},
  chatFail: false,
  chatOpenWindow: false,
  chatWaitingRoom: false,
  chatSelectedEntity: undefined,
  chatSelectedId: undefined,
  chatUnreadCount: 0,
  chatUnreadIndicatorSpace: '20px',
  loginUserLoading: false,
  currencyTable: undefined,
  currentCurrency: CurrencyKeyType.USD,
  currentLang: LANGUAGES.ES,
  updateAvailable: false,
  availCurrencies: [],
};

export type StateCurrency = {
  [key in keyof typeof CurrencyKeyType]?: Currency;
};

const coreReducer = createReducer(
  initialState,

  on(Actions.setCurrentUser, (state, { user }) => {
    return {
      ...state,
      user
    };
  }),

  on(Actions.setCountry, (state, payload) => ({
    ...state,
    country: payload.country
  })),

  on(Actions.updateUserTCC, (state, { tcc }) => {
    const nUser = {
      ...state.user,
      travelCredit: tcc
    } as User;

    return {
      ...state,
      user: Object.setPrototypeOf(nUser, Object.getPrototypeOf(state.user))
    };
  }),

  on(Actions.setCurrencyTable, (state, payload) => ({
    ...state,
    currencyTable: payload.value
  })),

  on(Actions.setCurrentCurrency, (state, payload) => ({
    ...state,
    currentCurrency: payload.value
  })),

  on(Actions.setAvailCurrencies, (state, payload) => ({
    ...state,
    availCurrencies: payload.value
  })),

  on(Actions.setCurrentLang, (state, payload) => ({
    ...state,
    currentLang: payload.value
  })),

  on(Actions.setChatAuthToken, (state, payload) => ({
    ...state,
    chatAuthToken: payload.token
  })),

  on(Actions.incChatUnreadCount, (state, payload) => ({
    ...state,
    chatUnreadCount: state.chatUnreadCount + payload.count
  })),

  on(Actions.setChatUnreadCount, (state, payload) => ({
    ...state,
    chatUnreadCount: payload.count
  })),

  on(Actions.decChatUnreadCount, (state, payload) => ({
    ...state,
    chatUnreadCount: state.chatUnreadCount - payload.count
  })),

  on(Actions.setChatUnreadIndicatorSpace, (state, payload) => ({
    ...state,
    chatUnreadIndicatorSpace: payload.space
  })),

  on(Actions.setChatFail, (state, payload) => ({
    ...state,
    chatFail: payload.fail
  })),

  on(Actions.setChatWaitingRoom, (state, payload) => ({
    ...state,
    chatWaitingRoom: payload.waiting,
  })),

  on(Actions.setChatWindowOpen, (state, payload) => ({
    ...state,
    chatOpenWindow: payload.open,
    chatSelectedEntity: payload.entity,
    chatSelectedId: payload.id,
  })),

  on(Actions.setLoginUserLoading, (state, payload) => ({
    ...state,
    loginUserLoading: payload.loading
  })),

  on(Actions.setMetaTitle, (state, payload) => ({
    ...state,
    metaTitle: payload.title
  })),

  on(Actions.setMetaUrl, (state, payload) => ({
    ...state,
    metaUrl: payload.url
  })),

  on(Actions.setMetaDescription, (state, payload) => ({
    ...state,
    metaDescription: payload.description
  })),

  on(Actions.setMetaImage, (state, payload) => ({
    ...state,
    metaImage: payload.image
  })),

  on(Actions.setUpdateAvailable, (state, payload) => ({
    ...state,
    updateAvailable: payload.update
  })),

);

export function reducer(state: State | undefined, action: Action): any {
  return coreReducer(state, action);
}
