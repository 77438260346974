import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { BedIcon, FavIcon } from '@rhbnb-nx-ws/domain';

import { MDI_SVG_ICONS } from '../static-data/mdi';

@Injectable({
  providedIn: 'root'
})
export class LoadIconsService {

  bedIcons = [
    {route: 'bed/big-personal.svg', name: BedIcon.BIG_PERSONAL},
    {route: 'bed/double.svg', name: BedIcon.DOUBLE},
    {route: 'bed/queen.svg', name: BedIcon.QUEEN},
    {route: 'bed/bunk.svg', name: BedIcon.BUNK_BED},
    {route: 'bed/king.svg', name: BedIcon.KING},
    {route: 'bed/child.svg', name: BedIcon.CHILD_BED},
    {route: 'bed/personal.svg', name: BedIcon.PERSONAL},
    {route: 'bed/sofa-bed-2.svg', name: BedIcon.SOFA_BED},
    {route: 'bed/mattress.svg', name: BedIcon.FLOOR_MATTRESS},
    {route: 'bed/bath.svg', name: 'bath'},
  ];

  favIcons = [
    {route: 'fav.svg', name: FavIcon.FAV},
  ];

  allSingleIcons = [
    ...this.bedIcons,
    ...this.favIcons,
  ];

  iconBasePath = '../../../assets/icons';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
  }

  loadAll(): void {
    this.matIconRegistry.addSvgIcon(
      'home_plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.iconBasePath}/flaticons/extras/anadir-casa.svg`)
    );

    this.matIconRegistry.addSvgIcon(
      'www',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.iconBasePath}/flaticons/extras/www.svg`)
    );

    MDI_SVG_ICONS.icons.forEach(svgIcon => {
      this.matIconRegistry.addSvgIcon(
        svgIcon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${MDI_SVG_ICONS.basePath}/${svgIcon}.svg`)
      );
    });

    this.allSingleIcons.forEach(i => (
      this.matIconRegistry.addSvgIcon(
        i.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.iconBasePath}/${i.route}`)
      )
    ));
  }
}
