import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromCore from './reducer';

export const selectCore = createFeatureSelector<fromCore.State>(fromCore.coreModuleKey);

export const selectUser = createSelector(
  selectCore,
  (state: fromCore.State) => state.user
);

export const selectCountry = createSelector(
  selectCore,
  (state: fromCore.State) => state.country
);

export const selectUserTCC = createSelector(
  selectCore,
  (state: fromCore.State) => state.user?.travelCredit
);

export const selectCurrencyTable = createSelector(
  selectCore,
  (state: fromCore.State) => state.currencyTable
);

export const selectCurrentCurrency = createSelector(
  selectCore,
  (state: fromCore.State) => state.currentCurrency
);

export const selectAvailCurrencies = createSelector(
  selectCore,
  (state: fromCore.State) => state.availCurrencies
);

export const selectCurrentLang = createSelector(
  selectCore,
  (state: fromCore.State) => state.currentLang
);

export const selectChatAuthToken = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatAuthToken
);

export const selectChatUnreadCount = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatUnreadCount
);

export const selectChatUnreadIndicatorSpace = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatUnreadIndicatorSpace
);

export const selectChatFail = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatFail
);

export const selectChatWindowOpen = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatOpenWindow
);

export const selectChatWaitingRoom = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatWaitingRoom
);

export const selectChatSelectedEntity = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatSelectedEntity
);

export const selectChatSelectedId = createSelector(
  selectCore,
  (state: fromCore.State) => state.chatSelectedId
);

export const selectLoginUserLoading = createSelector(
  selectCore,
  (state: fromCore.State) => state.loginUserLoading
);

export const selectMetaTitle = createSelector(
  selectCore,
  (state: fromCore.State) => state.metaTitle
);

export const selectMetaUrl = createSelector(
  selectCore,
  (state: fromCore.State) => state.metaUrl
);

export const selectMetaDescription = createSelector(
  selectCore,
  (state: fromCore.State) => state.metaDescription
);

export const selectMetaImage = createSelector(
  selectCore,
  (state: fromCore.State) => state.metaImage
);

export const selectJsonLdSchema = createSelector(
  selectCore,
  (state: fromCore.State) => state.jsonLdSchema
);

export const selectUpdateAvailable = createSelector(
  selectCore,
  (state: fromCore.State) => state.updateAvailable
);
