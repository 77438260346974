import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { FilterImagePipeService } from 'libs/shared-pipes/src/lib/pipes';
import { Image } from '../image';
import { IMAGE_SIZE_GALLERY_DEFAULT_W, IMAGE_SIZE_GALLERY_FULLSCREEN_W, IMAGE_SIZE_GALLERY_THUMBNAIL_W } from '@rhbnb-nx-ws/global-tokens';

@Component({
  selector: 'rhbnb-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GalleryComponent implements OnInit {

  @Input() images: Image[];
  @Input() seeAllLabel: string;
  @Input() errorImage: string;
  @Input() closeText = 'Close';

  selectedImageIndex = 0;
  showPreview = false;

  constructor(
    private filterImagePipeService: FilterImagePipeService,
    @Inject(IMAGE_SIZE_GALLERY_FULLSCREEN_W) public imgFullScreenW: number,
    @Inject(IMAGE_SIZE_GALLERY_THUMBNAIL_W) public imgThumbnailW: number,
    @Inject(IMAGE_SIZE_GALLERY_DEFAULT_W) public imgDefaultW: number,
  ) { }

  ngOnInit(): void {
  }

  get fullscreenImages() {
    return this.images ? this.images.map(img => ({
      image: img?.src
        ? this.filterImagePipeService.filter(img?.src, {w: this.imgFullScreenW, h: 0})
        : undefined,
      title: img?.label,
      thumbImage: img?.src
      ? this.filterImagePipeService.filter(img?.src, {w: this.imgThumbnailW, h: 0})
      : undefined,
    })) : [];
  }

  get galleryImages() {
    return this.images.map((i) => ({
      src: `${this.filterImagePipeService.filter(i?.src, { w: this.imgDefaultW, h: 0 })}`,
      label: i?.label,
    }))
  }

  openFullscreen(e) {
    const { index } = e;

    this.showPreview = true;
    this.selectedImageIndex = index;
  }

  closeEventHandler() {
    this.showPreview = false;
  }
}
